import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import _ from 'lodash'
import Layout from "../components/layout"
import PostCol2 from "../components/UI/PostCol2"
import SubscribeFull from "../components/UI/SubscribeFull"
import Introduction from "../components/UI/Introduction"


class Homepage extends Component {
    render() {
        let data = this.props.data.wpgraphql
        data.categories.edges = data.categories.edges;

        // function getBreadcrumb() {
        //     let bCrumb = [];
        //     bCrumb.push({
        //         text: "Home",
        //         url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}`
        //     })
        //     bCrumb.push({
        //         text: data.page.breadcrumbAcf.breadcrumbName,
        //     })
        //     return bCrumb;
        // }


        function constructMetaData(pageContext) {
            let payload = {
                title: pageContext.title,
                description: pageContext.metaDesc,
                canonical: `https://blog.enimeris.com/`,
                fbTitle: pageContext.opengraphTitle ? pageContext.opengraphTitle : pageContext.title,
                fbDescription: pageContext.opengraphDescription ? pageContext.opengraphDescription : pageContext.metaDesc,
                fbUrl: `https://blog.enimeris.com/`,
                fbType: "website",
                locale: "el",
                image: pageContext.opengraphImage ? pageContext.opengraphImage.sourceUrl : ""
            }
            return payload
        }

        return (
            <Layout metaData={constructMetaData(data.page.seo)} header={data.header} footer={data.footer} services={data.serviceCategories} >

                { data.page.blogPageAcf.indexImage && (
                    <Introduction backImg={data.page.blogPageAcf.indexImage} title={data.page.blogPageAcf.categorypage} />
                )}

                <div className="container px-4 md:px-0 max-w-6xl mx-auto">
                    <div className="mx-0 mt-12 flex flex-wrap w-full">
                        <div className="flex flex-wrap justify-center lg:justify-between w-full lg:w-2/3">
                            {data.posts.edges.map((post, i) => (
                                <PostCol2 post={data.posts.edges[i].node} key={`post-${i}`} />
                            ))}
                        </div>
                        <div className="w-full lg:w-1/3 pt-6">
                            <div>
                                <div className="cat-name">Κατηγοριες</div>
                                <div>
                                    <ul className="cat-ul">
                                        {data.categories.edges.map((node, i) => {
                                            if ((node.node.name !== "Uncategorized" && node.node.name !== "Μη κατηγοριοποιημένο" ) && (node.node.posts.edges.length > 0)) {

                                                return (
                                                    <li key={"cat" + i} className="cat-li">
                                                        <Link to={ `/${node.node.slug}/`} className="flex flex-wrap no-underline hover:no-underline">
                                                            {node.node.name}
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <SubscribeFull extraText={false} classes="p-4 mt-16" />
                        </div>
                    </div>
                    <SubscribeFull extraText={true} />
                </div>

            </Layout>

        )
    }
}

export default Homepage

export const pageQuery = graphql`
	query {
		wpgraphql {
			posts(first: 6 , where: {orderby: {field: MODIFIED, order: DESC}}) {
				edges {
				node {
					title
					excerpt
					slug
                    date
                    modified
					author{
						firstName
						lastName
						avatar{
							url
						}
					}
					postRT{
                        postReadTime
                    }
					featuredImage {
						sourceUrl
						altText
						imageFile {
						  childImageSharp {
							fluid{
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						  }
						}
					}
					categories{
					nodes{
						name
						slug
					}
					}
				}
				}
			}
			categories {
				edges {
				  node {
					name
					description
					slug
					posts {
						edges {
						  node {
							id
						  }
						}
					}
				  }
				}
			}
			page(id:"cG9zdDoxODc5") {
				title
                blogPageAcf{
                    indexImage{
                        sourceUrl
						altText
						imageFile {
						  childImageSharp {
							fluid(maxWidth: 2080, quality:60){
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						  }
						}
                    }
                    categorypage
                }
				seo {
					title
					metaDesc
					opengraphTitle
					opengraphDescription
					opengraphImage{
						sourceUrl
					  }
				}
            }
            

            footer: menu(id:"bmF2X21lbnU6MjQ="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }

            header: menu(id:"bmF2X21lbnU6MjI="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }

            serviceCategories(first: 100, where: {language: EL}) {
                edges {
                    node {
                        name
                        slug
                        language {
                            code
                        }
                        services {
                            edges {
                                node {
                                    uri
                                    title
                                }
                            }
                        }
                    }
                }
            }
		}
	}
`